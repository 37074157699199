import React, { useEffect, useState } from 'react'
import styles from '../styles/Layout.module.scss';
import { Navigate, Outlet } from 'react-router-dom';
import Topbar from './Topbar';
import AssetsList from './AssetsList';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';

export default function Layout({
  totalBalanceUsd,
  setSnackMessage,
  setSnackType,
  handleClick,theme,toggleTheme,setActiveAsset,activeAsset,activePrice,setActivePrice,setFavorites,favorites,toggleFavorite,currentRecords ,setCurrentRecords, account, balance,margin,eurToUsd,usdToEur,profit,setProfit}) {
    
  useEffect(()=>{
    const FetchList = async ()=>{
      try{
        const res = await axios.get(process.env.REACT_APP_test_url + '/api/assets', {
          headers: {
            'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
          }
        });
        setCurrentRecords(res.data)
      }catch(e){
        console.log(e)
      }
    }
    FetchList()
    const intervalId = setInterval(FetchList, 500);

    return () => {
      clearInterval(intervalId);
    };
  },[])
  
  useEffect(()=>{
    if(activeAsset){
      setActivePrice(currentRecords.find((val)=>val.Id==activeAsset?.Id)?.Price);
    }
  },[currentRecords])
  if(!secureLocalStorage.getItem('user')){
    return <Navigate to={'/login'}/>
    }else
  return (
    <div className={styles.layout}>
        <Topbar toggleTheme={toggleTheme} theme={theme} account={account} balance = {balance} margin = {margin}
          totalBalanceUsd={totalBalanceUsd}
          eurToUsd = {eurToUsd}
          usdToEur = {usdToEur}
          profit={profit}
          setProfit={setProfit}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}/>
        <div className={styles.page}>
            <AssetsList 
                theme={theme} 
                currentRecords={currentRecords} 
                setActivePrice={setActivePrice} 
                activePrice={activePrice} 
                favorites={favorites} 
                toggleFavorite={toggleFavorite} 
                setActiveAsset={setActiveAsset} 
                activeAsset={activeAsset}
                eurToUsd = {eurToUsd}
                usdToEur = {usdToEur}
                account={account}
                setSnackMessage={setSnackMessage}
                setSnackType={setSnackType}
                handleClick={handleClick}
            />
            <Outlet/>
        </div>
    </div>
  )
}
